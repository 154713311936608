import React from 'react';

import Field from './Field';

const defaultConfig = {
  disabled: false,
  required: false,
  placeholer: undefined,
  min: false,
  max: true,
};

export default function DateField(props) {
  const { name, type, value, error, onChange } = props;

  const config = { ...defaultConfig, ...props.config };

  const PARSABLE_DATE_FORMATS = ['x', 'YYYY-MM-DD', 'ddd MMM DD, YYYY'];
  const STORAGE_DATE_FORMAT = 'YYYY-MM-DD';
  const DISPLAY_DATE_FORMAT = 'ddd MMM DD, YYYY';

  const isDisabled = props.disabled || config.disabled;
  const isRequired = config.required;

  const inputRef = React.useRef();
  const handleLabelClick = () => inputRef.current.focus();

  React.useEffect(() => {
    $(inputRef.current).pickadate({
      min: config.min,
      max: config.max,
      clear: !isRequired,
      onRender: handleRender,
      onSet: handleSet,
    });
  });

  const formatDate = (date, dateFormat) => {
    return date ? moment(date, PARSABLE_DATE_FORMATS).format(dateFormat) : '';
  };

  const handleRender = () => {
    inputRef.current.value =
      value === null ? null : formatDate(moment(value).utc(), DISPLAY_DATE_FORMAT);
  };

  const handleSet = (context) => {
    const newDate = typeof context.select === 'object' ? context.select.pick : context.select;
    setValue(formatDate(newDate, STORAGE_DATE_FORMAT));
    inputRef.current.value = formatDate(newDate, DISPLAY_DATE_FORMAT);
  };

  const setValue = (newValue) => {
    const event = { target: { name: name, value: newValue } };
    onChange(event);
  };

  return (
    <Field
      className="vx-date-field"
      onLabelClick={handleLabelClick}
      {...props}
    >
      <input
        className="vx-date-field__input"
        type="text"
        name={name}
        data-value={value}
        onChange={onChange}
        disabled={isDisabled}
        required={config.required}
        placeholder={config.placeholder}
        ref={inputRef}
      />
      <i className="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon" />
    </Field>
  );
}
