import React from 'react';

import { formatMonetaryValue } from 'components/shared/utils';

const formatDate = (value) => moment(value).utc().format('MM-DD-YYYY');

function PurchaseRequestItemReceiving(props) {
  const { data, config } = props;

  const receivedStatus = config.received_status.options.find(
    (option) => option.id === data.received_status
  )?.description;

  /**************************************************************************************************/

  return (
    <tr>
      <td className="text-align-left">{data.description}</td>
      <td className="text-align-left">{data.model_number}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.unit_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">{data.quantity}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.total_amount, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">{receivedStatus}</td>
      <td className="text-align-center">{data.received_quantity}</td>
      <td className="text-align-center">
        {data.received_date === null ? '' : formatDate(data.received_date)}
      </td>
    </tr>
  );
}

export default React.memo(PurchaseRequestItemReceiving);
